<template>
  <MainForm v-on:submit="submit" />
</template>
<script>
import MainForm from './form'

export default {
  name: 'pageCreate',
  created() {
    this.$store.dispatch('tag/FETCH')
  },
  methods: {
    submit() {
      const vm = this
      vm.$store.dispatch('page/STORE').then((res) => {
        vm.$router.push({ path: '/pages' })
      })
    },
  },
  components: {
    MainForm,
  },
}
</script>
